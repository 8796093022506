/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";

// import GAuth from "vue3-google-oauth2";
const app = createApp(App).use(Quasar, quasarUserOptions);
app.use(router);
app.use(store);
// const gauthOption = {
//   clientId:
//     "806222051247-39dkfmcmdojbtfofvh9jsvdhin575osd.apps.googleusercontent.com",
//   scope: "profile email",
//   prompt: "consent",
//   fetch_basic_profile: false,
// };
// app.use(GAuth, gauthOption);
app.mount("#app");
/* eslint-disable */